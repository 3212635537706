<template>
    <div>
        <card-header title="Flight Information" icon="fa-plane"/>

        <card-toolbar>
            <button @click="$openCard('new-flight', {registrationId: props.registrationId}, card)"><i class="fas fa-plus mr-2"/>New Flight</button>
        </card-toolbar>

        <card-body>
            <card-list>
                <flight-list-item
                        :card="card"
                        :flight="flight"
                        v-for="flight in flights"
                        :active="child && child.definition.component === 'edit-flight' && child.props.flightId === flight.id.toString()"
                        @click="$openCard('edit-flight', {registrationId: props.registrationId, flightId: flight.id}, card)"
                />
            </card-list>
        </card-body>
    </div>
</template>

<script>
    import {client as http} from '../../http_client';

    import CardHeader from '../../TIER/components/CardHeader';
    import CardToolbar from '../../TIER/components/CardToolbar';
    import CardBody from '../../TIER/components/CardBody';
    import CardList from '../../TIER/components/CardList';
    import FlightListItem from '../../components/FlightListItem';

    export default {
        props: ['card', 'child', 'props'],
        components: {FlightListItem, CardBody, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                flights: []
            }
        },
        methods: {
            loadFlights: function(force) {
                this.$emit('loading', true);
                http.get('/api/registrations/' + this.props.registrationId + '/flights', {force}).then(response => {
                    this.flights = response.data;
                    this.$emit('loading', false);
                });
            },
            reload: function() {
                this.loadFlights(true);
            }
        },
        mounted() {
            this.loadFlights();
        }
    };
</script>
